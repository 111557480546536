export const emailPattern = /^[^\s@]{1,64}@[^\s@]{1,255}\.[^\s@]{2,16}$/
export const last4DigitSsnPattern = /^[/\d]{4}$/
export const routingNumberPattern = /^[/\d]{9}$/
export const UserTypedEmailKey = 'TypedEmail'
export const BrowserSessionKey = 'BrowserSessionKey'
export const RcmUserCookieName = 'RcmUserClientV2.0'
export const ProfileJsonKey = 'ProfileJsonKey'
export const ContextJsonKey = 'ContextJsonKey'
export const ETZoneKey = 'America/New_York'

//Api End points
export const checkMaintenanceWindowUrl = '/registration/api/Maintenance/GetMaintenanceMessage?s=w'
export const firstTimeLoginDetailsUrl = '/profileapi/api/LoginInfo/GetFirstTimeLoginDetails'
export const validateLoginDetailsUrl = '/profileapi/api/LoginInfo/ValidateLoginDetails'
export const updateFirstTimeLoginUrl = '/profileapi/api/logininfo/UpdateFirstTimeLogin'
export const fetchNfsProfileUrl = '/profileapi/odata/GetAllAccounts'
export const fetchTemplateUrl = '/templateapi/odata/template'
export const addPhoneMethodUrl = '/multifactorauthapi/api/PhoneMethods/add'
export const initProfileUrl = '/profileapi/odata/InitProfile'
export const advisorTeamDetailsUrl = '/profileapi/odata/GetAdvisorTeamDetails'
export const advisorImageUrl = '/profileapi/odata/GetAdvisorImage'
export const invalidateCacheUrl = '/profileapi/odata/InvalidateCache'
export const IncomeUrl = '/holdings/odata/Position/GetEstimatedIncomeSummary'
export const GetIncomeSummaryChartViewURL = '/holdings/odata/Position/GetIncomeSummaryChartView'
export const ProjectedIncomeSummaryUrl = '/holdings/odata/Position/GetProjectedIncomeSummary'
export const RealizedGainLossDetailsUrl = '/holdings/odata/Position/GetRealizedGainLoss'
export const GainLossDetailsUrl = '/holdings/odata/Position/GetRealizedGainLoss'
export const InvestmentSummaryUrl = '/holdings/odata/Position/Top10Holding'
export const AssetAllocationUrl = '/holdings/odata/Position/CategoryPosition'
export const HistoricalValueUrl = '/assetallocapi/api/assetallocation'

export const AssetAllocationSecurityUrl = '/holdings/odata/Position/GetPositionByCategorySecurity'
export const ActivitiesSummaryUrl = '/activityapi/odata/activitysummary'
export const ActivitiesSummaryGroupUrl = '/activityapi/odata/ActivitySummary/group'
export const ActivitiesSummaryCountUrl = '/activityapi/odata/ActivitySummary/count'
export const AccountListBalanceSummary = '/balance/odata/balancesummary'
export const StaticConfigUrl = '/templateapi/api/Template/StaticConfig?version=v2'

export const HoldingsByAsset = '/holdings/odata/Position/GetPositionByCategorySecurity'
export const HoldingsByAccount = '/holdings/odata/Position/AccountPosition/AssetClass'
export const TaxSlot = '/tas/odata/TAS/TaxLotOpenByAcctSec'
export const AccountBalanceDetailsUrl = '/balance/odata/balancedetails'
export const OnlinePortalCustomizationUrl = '/profileapi/odata/onlineportalcustomization/getwidgets'
export const UpdateWidgetOrderUrl = '/profileapi/odata/onlineportalcustomization/v2'
export const DocumentsUrl = '/documentsapi/odata/document/RetrieveDocumentList'
export const DocumentsTradeConfirmationsUrl = '/documentsapi/odata/document/RetrieveTradeConfDocumentList'
export const StatementUrl = '/documentsapi/odata/document/RetrieveDocument'
export const TrustStatementsUrl = '/documentsapi/api/Reports/GetClientOnlineReportListV2'
export const DocumentReportsUrl = '/documentsapi/api/Reports/GetReportsV2'
export const TrustStatementsDocUrl = '/documentsapi/api/reports/DownloadReport'
export const PerformanceReportDownloadPdfUrl = '/documentsapi/api/Reports/DownloadMultipleFilesV2'
export const StitchedPdfDocumentUrl = '/documentsapi/odata/document/RetrieveStichedPdfDocument'
export const PreferencesUrl = '/profileapi/odata/preferences'
export const StitchedPdfTrustStatementsUrl = '/documentsapi/api/reports/GetStitchedReport'
export const StitchedPdfTrustStatementZipUrl = '/documentsapi/api/reports/DownloadMultipleFiles'
export const StitchedPdfDocumentZipUrl = '/documentsapi/odata/document/DownloadMultipleFiles'
export const MMSummaryUrl = '/mm/odata/MMSummary'
export const MMValidateUrl = '/mm/odata/MMValidate'
export const MMSubmitUrl = '/mm/odata/mmsubmit'
export const GetSIUrl = '/cashmanagement/mm/odata/GetSI'
export const GetEligiblePairsUrl = '/cashmanagement/mm/odata/GetEligiblePairs'
export const GetAbaDetailUrl = '/cashmanagement/mm/odata/GetABA'
export const PostEligibleTosUrl = '/cashmanagement/mm/odata/PostEligibleTos'
export const GetXferFromUrl = '/cashmanagement/mm/odata/GetXferFrom(Y)'
export const MMSIValidateUrl = '/mm/odata/MMStandingInstructionValidate'
export const UploadSIDocsUrl = '/acctopngapi/api/BPM/UpdateSIDoc'
export const MMSISubmitUrl = '/mm/odata/MMStandingInstructionSubmit'
export const getAssetAggrAcctListUrl = "/profileapi/odata/aggregatedaccount('1')"
export const updateAssetAggrAcctListUrl = '/profileapi/odata/aggregatedaccount'
export const updateExternalAccountNickname = '/profileapi/odata/updateaccountnickname'
export const deleteAggrAcctUrl = '/assetag/odata/ExtAssetAggregationSubmit'
export const managedAssestsUrl = '/manualassets/api/ManageAssets'
export const managedAssestsEditUrl = '/manualassets/api/ManageAssets/UpdateManualAsset'
export const manualAccountDetailsUrl = '/manualassets/api/ManageAssets/GetManualAccountDetails'
export const getTransfersFaq = '/templateapi/api/Template/FAQ'
export const PerformanceURL = '/holdings/odata/Position/PerformanceData'
//Graph Api End Points
export const userPhotoUrl = "/me/photos('48x48')/$value"
export const accountSelectorContextURL = 'profileapi/odata/context'
export const getGroupsUrl = `me/memberof/$/microsoft.graph.group?$filter=groupTypes/any(a:a eq 'unified')`
export const getUserIdentitiesUrl = `/profileapi/odata/GetUserIdentities`

//#region MyProfile and settings
export const MMSummaryURL = '/mm/odata/MMSummary'
export const BeneficiariesAccountDetailsURL = '/profileapi/api/accountinfo/GetActHldrBeneDetails'
export const GenerateTurboTaxTokenUrl = '/extauth/api/UserAuth/GenerateOTToken'
export const UpdateTotalNetworthAccountsURL = '/profileapi/odata/UpdateTotalNetworthAccounts'
export const SendEmailURL = '/mail/api/mailV2'
export const GetMyProfileURL = '/profileapi/odata/GetMyProfile'
export const FeedbackURL = '/templateapi/api/feedback/save'
//#endregion MyProfile and settings

//INCOME API END POINTS
export const AccountIncomeSecurityUrl = '/holdings/odata/Position/GetProjectedIncomeSummary'
export const AccountEstimatedIncomeSecurityUrl = '/holdings/odata/Position/GetEstimatedIncomeSummary'
export const IncomeAccountUrl = 'holdings/odata/Position/GetProjectedIncomeSummary'
export const SummaryHistorical = 'holdings/odata/Position/GetIncomeMonthlySummary'
export const IncomeAccountHistoricalUrl = 'holdings/odata/Position/GetEstimatedIncomeSummary'

//#region Account page URL
export const AccountsActivitiesURL = '/accounts/activities'
export const AccountsComeURL = '/accounts/income'
export const AccountsInvestmentsURL = '/accounts/investments'
export const AccountsGainlossesURL = '/accounts/gainlosses'
export const AccountsPerformanceURL = 'holdings/odata/position/GetPerformanceData(account)'

//#endregion

//QUICK LINKS API END POINTS
export const QuickLinksGetUrl = '/templateapi/api/Shortcuts/GetShortcuts'
export const QuickLinksSetUrl = '/templateapi/api/Shortcuts/SetShortcuts'

//E-Delivery API END POINTS
export const GetEDeliveryUrl = "/profileapi/odata/profile(',true')"
export const UpdateEDeliveryUrl = '/profileapi/odata/profile'

// Account Hub
export const UpdateNickNameURL = 'profileapi/odata/updateaccountnickname'
export const UpdateAccountGroupURL = 'profileapi/odata/accountgroup/update'
export const DeleteAccountFromGroupURL = '/profileapi/odata/accountgroup'
export const CreateAccountGroupURL = '/profileapi/odata/accountgroup/create'
export const AccountGroupURL = '/profileapi/odata/accountgroup'
export const UngroupAccountURL = '/profileapi/odata/GetUnGroupedAccounts'
export const UpdateAccountOrderURL = 'profileapi/odata/accountsort'
export const UpdateCustomAccountOrderURL = 'profileapi/odata/accountgroupsort'
export const UpdateGroupNameURL = '/profileapi/odata/accountgroup'
export const GroupDefaultURL = '/profileapi/odata/accountgroup'

//Strategic insights
export const StrategicInsightsURL = '/profileapi/odata/GetRockefellerPerspectives'
export const StrategicInsightsByIdURL = '/profileapi/odata/GetRockefellerPerspectiveById'
export const StrategicInsightsByText = '/profileapi/odata/SearchInsights'

// Registration
export const ValidateRegistrationKeyURL = '/registration/api/registration/ValidateInvite'
export const ValidateRegistrationEmailURL = '/registration/api/registration/ValidateRegistration'
export const GenerateOTPRequestURL = '/registration/api/registration/GenerateOTP'
export const VerifyOTPRequestURL = '/registration/api/registration/VerifyOTP'
export const VerifyAcctSsnRequestURL = '/registration/api/registration/VerifyLogin'
export const UpdateRegistrationURL = '/profileapi/api/LoginInfo/UpdateMFA'
export const CheckRedirectVersionURL = '/registration/api/Registration/CheckRoute'

// Emoney
export const EmoneySearchFiURL = '/assetag/api/emoney/institutions'
export const EmoneyFetchUserURL = '/assetag/api/emoney/user'
export const EmoneyFetchClientConnectionURL = '/assetag/api/emoney/connection'
export const EmoneyOauthSessionURL = '/assetag/api/emoney/oauth/session'
export const EmoneyAccountsURL = '/assetag/api/emoney/accounts'
export const EmoneyInstitutionConnectionsURL = '/assetag/api/emoney/institution/connections'
export const EMoneyAccountConnectionURL = '/assetag/api/emoney/account/connection'
export const EMoneyUserConnectionsURL = '/assetag/api/emoney/user/connections'
export const EMoneyConnectionAccountsURL = '/assetag/api/emoney/connection/account'

// Market Data
export const MarketDataSnapQuoteSearchURL = '/marketdata/api/security/search'
export const MarketDataSnapQuoteDetailByIdentifierURL = '/marketdata/api/security'
export const CompanyProfileURL = '/marketdata/api/security/profile'
export const CompanyExecutivesURL = '/marketdata/api/security/executives'
export const MarketNewsArticlesURL = '/marketdata/api/news/articles'
export const CompanyOverviewTimeSeriesURL = '/marketdata/api/security/timeseries'
export const AnalystRatingsURL = '/marketdata/api/security/analystrating'
export const MarketDataPageCustomizationURL = '/templateapi/api/Template/pagecustomization'
export const OverviewWatchListURL = '/marketdata/api/Factset/articles'
export const EarningsWatchListURL = '/marketdata/api/Factset/GetTimeSeriesData'
export const WatchListsURL = '/marketdata/api/watchlist'
export const AddWatchListItemURL = '/marketdata/api/watchlist/watchlistitem'
export const MarketMoversURL = '/marketdata/api/markets/movers'
export const SectorPerformanceURL = '/marketdata/api/markets/sectorperformance'

//Prospect Experience
export const PropsectExperienceURL = '/templateapi/api/Template/prospectcontent'

//App store
export const AppleAppStoreLink = 'https://apps.apple.com/us/app/rockefeller-capital-management/id1466959277'
export const AndroidAppStoreLink = 'https://play.google.com/store/apps/details?id=com.rockco.rcmclientmobileandroid'

//Account selector type
export const AccountSelectorType = { ROCKO: 'rocko', EXTERNAL: 'external', OTHERS: 'others' }
export const DateRanges = {
  PRIORMONTH: {
    label: 'Prior Month',
    value: 'PriorMonth'
  },
  PRIOR3MONTHS: {
    label: 'Prior 3 Months',
    value: 'Prior3Months'
  },
  PRIOR6MONTHS: {
    label: 'Prior 6 Months',
    value: 'Prior6Months'
  },
  PRIOR12MONTHS: {
    label: 'Prior 12 Months',
    value: 'Prior12Months'
  },
  CURRENTYEAR: {
    label: 'Current Year',
    value: 'CurrentYear'
  },
  PRIORYEAR: {
    label: 'Prior Year',
    value: 'PRIORYEAR'
  },
  CUSTOMRANGE: {
    label: 'Custom Range',
    value: 'CustomRange'
  },
  YESTERDAY: {
    label: 'Yesterday',
    value: 'Yesterday'
  },
  LAST5DAYS: {
    label: 'Last 5 Days',
    value: 'Last5Days'
  },
  LAST7DAYS: {
    label: 'Last 7 Days',
    value: 'Last7Days'
  },
  LAST30DAYS: {
    label: 'Last 30 Days',
    value: 'Last30Days'
  },
  LAST90DAYS: {
    label: 'Last 90 Days',
    value: 'Last90Days'
  },
  YEARTODATE: {
    label: 'Year to Date',
    value: 'YEARTODATE'
  },
  MONTHYEAR: {
    label: 'Single month/year',
    value: 'SingleMonthYear'
  }
}
export const AccountSelector = [
  { name: 'Rockefeller Accounts', type: 'rocko' },
  { name: 'External Accounts', type: 'external' },
  { name: 'Other Assets/Liabilities', type: 'others' }
]
export const exportExcelNumFmt = '$#,##0.00;-$#,##0.00'
export const exportExcelQtyFmt = '#,##0.00;-#,##0.00'
export const exportExcelAmtFmt = '$#,##0.00;-$#,##0.00'
export const exportExcelPercentageFmt = '#,##0.00"%"'
export const exportExcelRightAlignTxtFmt = ';;;* @'

//#region common description
export const NODATATEXT = 'No Data Found'
export const VIEWMORETEXT = 'View more'
//#endregion

//#region common status
export const CHARTTEXT = 'chart'
export const TABLETEXT = 'table'
export const OPENSTATUSTEXT = 'open'
export const CLOSESTATUSTEXT = 'close'
//#endregion

export const getMonthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]
export const BalanceDetailsUrl = '/accounts/balances'
export const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)
